import { Trans } from '@lingui/react'
import PropTypes from 'prop-types'

export default function OneTimeCredentialsFailureMessages({ status }) {
  switch (status) {
    case 6100:
      return <Trans id="OneTimeCredentialsFailureMessages.credentialsError" />
    case 6102:
      return <Trans id="OneTimeCredentialsFailureMessages.noLocations" />
    case 6103:
      return <Trans id="OneTimeCredentialsFailureMessages.timeout" />
    case 6104:
      return <Trans id="OneTimeCredentialsFailureMessages.magisterScriptError" />
    default:
      return <Trans id="OneTimeCredentialsFailureMessages.unknownError" />
  }
}

OneTimeCredentialsFailureMessages.propTypes = {
  status: PropTypes.number,
}

OneTimeCredentialsFailureMessages.defaultProps = {
  status: null,
}
