import {
  Card,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/react'
import {
  Check,
} from '@Infowijs-eng/component-library/icons'

import {
  useEffect,
} from 'react'
import ConfettiGenerator from 'confetti-js'
import PropTypes from 'prop-types'

function OneTimeCredentialsCompleted({ importerData }) {
  useEffect(() => {
    // Pass in the id of an element
    const confettiSettings = {
      target: 'confetti-canvas',
      max: 500,
      clock: 45,
      respawn: false,
      rotate: true,
    }
    const confetti = new ConfettiGenerator(confettiSettings)
    confetti.render()

    return () => confetti.clear()
  }, [])
  return (
    <>
      <Card className="max-w-3xl p-8 z-10 text-center">
        <div className="p-4 space-y-4">
          <div className="bg-green-50 rounded-full inline-block mx-auto p-4 text-green-500">
            <Check className="text-[2.25em]" />
          </div>
          <Heading fontSize={Heading.size.H3}>
            <Trans id="OneTimeCredentialsCompleted.title" />
          </Heading>
          <div className="w-4/5 mx-auto leading-loose">
            <p>
              <Trans
                id="OneTimeCredentialsCompleted.thanks"
                values={{ customerName: importerData.customerTitle || '' }}
              />

            </p>
            <p>
              <Trans id="OneTimeCredentialsCompleted.close" />

            </p>
          </div>
        </div>
      </Card>
      <canvas className="fixed inset-0" id="confetti-canvas" />
    </>
  )
}

OneTimeCredentialsCompleted.propTypes = {
  importerData: PropTypes.shape({
    customerTitle: null,
  }),
}

OneTimeCredentialsCompleted.defaultProps = {
  importerData: {},
}

export default OneTimeCredentialsCompleted
