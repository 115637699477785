import {
  Card,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/react'
import {
  ExclamationCircle,
} from '@Infowijs-eng/component-library/icons'

export default function OneTimeCredentialsUnknown() {
  return (
    <Card className="max-w-3xl p-8 z-10 text-center">
      <div className="p-4 space-y-4">
        <div className="bg-aqua-50 rounded-full inline-block mx-auto p-4 text-aqua-600">
          <ExclamationCircle className="text-[2.25em]" />
        </div>
        <Heading fontSize={Heading.size.H3}>
          <Trans id="OneTimeCredentialsUnknown.title" />
        </Heading>
        <div className="w-4/5 mx-auto leading-loose">
          <Trans id="OneTimeCredentialsUnknown.message" />
        </div>
      </div>
    </Card>
  )
}
