import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import useRequest from '../lib/useRequest'

export default function useStoreOneTimeCredentials(importerId, securityToken) {
  const requestConfig = {
    url: `${serviceEndpoints.importers}/${importerId}/credentials/${securityToken}`,
    method: 'POST',
  }

  return useRequest(requestConfig)
}
