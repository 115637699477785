import {
  useState,
} from 'react'
import axios from 'axios'

function ApiException(errors) {
  this.errors = errors
}

export default function useRequest(initialRequestConfig = {}, {
  onResolve,
  onReject,
  defaultLoadingState = false,
} = {}) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(!!defaultLoadingState)
  const [errorMessages, setErrorMessages] = useState(null)

  async function fetch(requestConfig = {}) {
    setLoading(true)
    setErrorMessages(null)

    let response

    try {
      response = await axios({
        headers: {
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
          ...(initialRequestConfig.headers || {}),
          ...(requestConfig.headers || {}),
        },
        ...(initialRequestConfig || {}),
        ...(requestConfig || {}),
      })
      setData(response.data && response.data.data ? response.data.data : response.data)
      if (onResolve) {
        onResolve(response)
      }
    } catch (err) {
      if (onReject) {
        onReject(err)
      }
      if (err.response && err.response.data && err.response.data.errors) {
        setErrorMessages(err.response.data.errors)
        throw new ApiException(err.response.data.errors)
      } else {
        setErrorMessages([{ title: 'Unknown error occurred', code: 1 }])
        throw new ApiException([{ title: 'Unknown error occurred', code: 1 }])
      }
    } finally {
      setLoading(false)
    }

    return response
  }

  return [fetch, data, loading, errorMessages]
}
