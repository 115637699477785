import {
  BubblePage,
  Infowijs,
} from '@Infowijs-eng/component-library/components'
import {
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import OneTimeCredentialsMagister from '../components/OneTimeCredentials/OneTimeCredentialsMagister'
import OneTimeCredentialsCompleted from '../components/OneTimeCredentials/OneTimeCredentialsCompleted'
import OneTimeCredentialsFailure from '../components/OneTimeCredentials/OneTimeCredentialsFailure'
import useGetOneTimeCredentials from '../modules/requests/useGetOneTimeCredentials'
import OneTimeCredentialsUnknown from '../components/OneTimeCredentials/OneTimeCredentialsUnknown'

const screenState = {
  DEFAULT: 0,
  SUCCESS: 1,
  FAILURE: 2,
  UNKNOWN: 3,
}

export default function OneTimeCredentialsForMagisterScreen() {
  const { importerId, securityToken } = useParams()
  const [screenStep, setScreenStep] = useState(screenState.DEFAULT)
  const [failureErrors, setFailureErrors] = useState(null)
  const [getOneTimeCredentials, importerData, loading] = useGetOneTimeCredentials(importerId, securityToken)

  useEffect(() => {
    getOneTimeCredentials()
      .catch((e) => {
        if (!(
          (e.errors && e.errors[0] && e.errors[0].status >= 6100)
        && (e.errors && e.errors[0] && e.errors[0].status <= 6199))
        ) {
          setScreenStep(screenState.UNKNOWN)
        }
      })
  }, [])

  if (loading) {
    return (<div>Loading...</div>)
  }

  return (
    <BubblePage>
      <>
        {screenStep === screenState.DEFAULT && (
          <OneTimeCredentialsMagister
            importerData={importerData}
            loading={loading}
            onSuccess={() => setScreenStep(screenState.SUCCESS)}
            onFailure={(errors) => {
              setScreenStep(screenState.FAILURE)
              setFailureErrors(errors)
            }}
          />
        )}
        {screenStep === screenState.SUCCESS && (
          <OneTimeCredentialsCompleted
            importerData={importerData}
          />
        )}
        {screenStep === screenState.FAILURE && (
          <OneTimeCredentialsFailure
            onRetry={() => setScreenStep(screenState.DEFAULT)}
            errors={failureErrors}
          />
        )}
        {screenStep === screenState.UNKNOWN && (
          <OneTimeCredentialsUnknown />
        )}
      </>
      <Infowijs />
    </BubblePage>
  )
}
