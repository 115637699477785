import {
  Button,
  Card,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { Trans } from '@lingui/react'
import {
  XMark,
} from '@Infowijs-eng/component-library/icons'
import PropTypes from 'prop-types'
import OneTimeCredentialsFailureMessages from './OneTimeCredentialsFailureMessages'

function OneTimeCredentialsFailure({ errors, onRetry }) {
  return (
    <Card className="max-w-3xl p-8 z-10 text-center">
      <div className="p-4 space-y-4">
        <div className="bg-red-50 rounded-full inline-block mx-auto p-4 text-red-600">
          <XMark className="text-[2.25em]" />
        </div>
        <Heading fontSize={Heading.size.H3}>
          <Trans id="OneTimeCredentialsFailure.title" />
        </Heading>
        <div className="w-4/5 mx-auto leading-loose">
          <p>
            <OneTimeCredentialsFailureMessages status={errors && errors[0] && errors[0].status} />
          </p>
        </div>
        <div className="flex flex-row space-x-4 justify-center items-center">
          <Button onClick={onRetry}>
            <Trans id="OneTimeCredentialsFailure.button.retry" />
          </Button>
          <Button primary href="https://support.infowijs.nl" target="_blank">
            <Trans id="OneTimeCredentialsFailure.button.contact" />
          </Button>
        </div>
      </div>
    </Card>
  )
}

OneTimeCredentialsFailure.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape()),
  onRetry: PropTypes.func.isRequired,
}

OneTimeCredentialsFailure.defaultProps = {
  errors: null,
}

export default OneTimeCredentialsFailure
