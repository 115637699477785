import { I18nProvider } from '@lingui/react'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { i18n } from '@lingui/core'
import {
  en,
  nl,
} from 'make-plural/plurals'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import MainScreen from './screens/MainScreen'
import OneTimeCredentialsForMagisterScreen from './screens/OneTimeCredentialsForMagisterScreen'
import { messages as messagesNL } from './locales/nl_NL/messages'
import { messages as messagesEN } from './locales/en_US/messages'

require('dayjs/locale/en')
require('dayjs/locale/nl')

i18n.loadLocaleData('nl-NL', { plurals: nl })
i18n.loadLocaleData('en-US', { plurals: en })
i18n.load('nl-NL', messagesNL)
i18n.load('en-US', messagesEN)

export default function routes() {
  useEffect(() => {
    i18n.activate('nl-NL')
    dayjs.extend(calendar)
    dayjs.locale('nl')
  }, [])

  return (
    <BrowserRouter>
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <Routes>
          <Route
            path="/one-time-credentials/magister/:importerId/:securityToken"
            element={<OneTimeCredentialsForMagisterScreen />}
          />
          <Route
            path="/*"
            element={<MainScreen />}
          />
        </Routes>
      </I18nProvider>
    </BrowserRouter>
  )
}
