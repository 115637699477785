import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import useRequest from '../lib/useRequest'

export default function useGetOneTimeCredentials(importerId, securityToken) {
  const requestConfig = {
    url: `${serviceEndpoints.importers}/${importerId}/credentials/${securityToken}`,
  }

  return useRequest(requestConfig, { defaultLoadingState: true })
}
